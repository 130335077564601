import * as React from "react";
import { NavLink, useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Checkbox,
  Stack,
  Text,
  useColorModeValue,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";
import { CustomButton } from "styleguide/Button"
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import api from "services/api";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useEmailStore } from "store/reducers/email";
import axios from "axios";
import $ from "jquery";
import "jquery-mask-plugin";
import "bootstrap";
import {CustomInput} from "styleguide/Input"

import { useUserStore } from "store/reducers/user";
import { useParams } from 'react-router-dom';
import { notifyAlert } from "utils/notification";

const SignUp = () => {

  const { push } = useHistory();

  const updateEmail = useEmailStore((state) => state.updateEmail);

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState("");
  const [momName, setMomName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [cpf, setCpf] = React.useState("");
  const [cellphone, setCellphone] = React.useState("");
  const [pep, setPep] = React.useState({ sim: false, nao: true });
  const [terms, setTerms] = React.useState(false);
  const [birthdate, setBirthDate] = React.useState("");
  const [ageError, setAgeError] = React.useState("");
  const [erro, setErro] = React.useState("");
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);

  const [show, setShow] = React.useState(false);
  const [show2, setShow2] = React.useState(false);

  const handleClick = () => setShow(!show);
  const handleClick2 = () => setShow2(!show2);

  const handlePepChange = (value: string) => {
    setPep({ sim: value === "sim", nao: value === "nao" });
};

  const handleSignUp = async (e: React.SyntheticEvent) => {

    function useQueryParams<T extends Object>(): T {
      const params = new URLSearchParams(window.location.search)

      let data: { [key: string]: any } = {}

      for (const key of params.keys()) {
        data[key] = params.get(key)
      }

      return data as T
    }

    type Params = {
      email?: string
    }

    const params1 = useQueryParams<Params>();
    const emailFrom = params1?.email;
    const currentDate = new Date();
    const birthDate = new Date(birthdate);
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    const month = currentDate.getMonth() - birthDate.getMonth();

    if (age < 18 || (age === 18 && month < 0)) {
      handleShowerro();
      setErro("Você precisa ser maior de 18 anos para se cadastrar.");
      setLoading(false);
      return;
    }

    try {
      updateEmail("");
      e.preventDefault();

      if (!terms) {
        notifyAlert("Aceite os termos para se cadastrar.");
        return;
      }

      setLoading(true);

      const response = await api.post(`${process.env.REACT_APP_API_URL}/register`, {
        name,
        momName,
        email,
        cpf,
        cellphone,
        password,
        password_confirm: passwordConfirm,
        pep: pep.sim,
        indicate: "nelsonespindolalves@gmail.com",
        birthdate
      });

      if (emailFrom != undefined) {

        const userId = 0;

        const formData = new FormData();
        formData.append("user_indication_from_id", userId.toString());
        formData.append("user_indication_to_id", response.data.id);
        formData.append("email", email);
        formData.append("status", "Ativo");
        formData.append("emailFrom", emailFrom);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/seller-indication`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          );
        } catch (error) {

        }

      }

      handleShow();

    } catch (error: any) {
      handleShowerro()
      console.log(error);

      $('#texto').html(error.response.data[0]);
    } finally {
      setLoading(false);
    }
  };


  $(() => {
    $('#cpf').mask('000.000.000-00');
    $('#imagemLogo').attr('style', 'position:fixed;');
    $('#cellphone').mask('(00) 0 0000-0000');
    $('#birthdate').mask('00/00/0000');
  });

  const [modalshow, setModal] = React.useState(false);

  const handleClose = () => {
    setModal(false);
    push("/auth/confirm");
  };

  const handleBirthdateChange = (e) => {
    const inputDate = e.target.value;
    setBirthDate(inputDate);
    const currentDate = new Date();
    const birthDate = new Date(inputDate.split("/").reverse().join("-"));
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();
    const dayDiff = currentDate.getDate() - birthDate.getDate();

    if (age < 18 || (age === 18 && (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)))) {
      setAgeError("Você precisa ser maior de idade para realizar o cadastro.");
      setIsButtonDisabled(true); 
    } else {
      setAgeError("");
      setIsButtonDisabled(false); 
    }
  };

  const handleShow = () => setModal(true);
      const [modalerro, setModalerro] = React.useState(false);

  const handleCloseerro = () => {
    setModalerro(false);
  };


  const handleShowerro = () => setModalerro(true);

  function TestaCPF(strCPF: any) {
    var Soma;
    var Resto;
    Soma = 0;
    strCPF = strCPF.replace(/[.-]/g, '');
    if (strCPF == "00000000000")
      return false;
    for (var i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11))
      Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)))
      return false;
    Soma = 0;
    for (var i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11))
      Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11)))
      return false;
    return true;
  }



  const [cpfValido, setCpfValido] = React.useState(true);

  const handleCpfChange = () => {
    if (cpf.length >= 14) {
      const cpfteste = cpf;
      const cpfSemMascara = cpfteste.replace(/[.-]/g, '');
      const isValidCpf = TestaCPF(cpfSemMascara);
      setCpfValido(isValidCpf);
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>

      <Flex
        className="mt-0 pt-0"
        maxW={{ base: "100%", md: "max-content", lg: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="auto"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "0px", md: "10px" }}
        px={{ base: "0px", md: "0px" }}
        flexDirection="column"
      >

        <Modal isOpen={modalshow} onClose={handleClose}>
          <ModalContent>
            <ModalHeader>Confirme seu email!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>Um email com o código de confirmação foi enviado para você!</ModalBody>
            <ModalFooter>
              <Button variant="primary" onClick={handleClose}>
                Confirmar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>


        <Modal isOpen={modalerro} onClose={handleCloseerro}>
          <ModalContent>
            <ModalHeader>Ops, tivemos um erro!</ModalHeader>
            <ModalCloseButton />
            <ModalBody id="texto"></ModalBody>
            <ModalFooter>
              <Button variant="primary" onClick={handleCloseerro}>
                Tentar Novamente
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box me="auto" className="mt-0 pt-0">
          <Heading color={textColor} fontSize="36px" mb="5px" className="cadastro">
            Criar uma nova conta
          </Heading>
          <Text
            className="cadastro"
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            É rápido e fácil.
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          className="pt-0"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
          h="100%"
          display={'flex'}
        >
          <Flex align="center"></Flex>
          <form
            onSubmit={(e) => {
              handleSignUp(e);
            }}
          >
            <FormControl>
              <FormLabel
                ms="1px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Nome Completo<Text color={brandStars}>* (30 max. caracteres)</Text>
              </FormLabel>
              <Input
                id="nomeCompleto"
                className="input-group-text text-left"
                isRequired={true}
                fontSize="sm"
                placeholder="Nome completo"
                mb="14px"
                size="lg"
                type={"text"}
                variant="auth"
                maxLength={30}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <FormLabel
                ms="1px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                CPF<Text color={brandStars}>*</Text> {!cpfValido && <p style={{ color: 'red' }}>CPF inválido!</p>}
              </FormLabel>
              <Input
                id="cpf"
                placeholder="000.000.000-00"
                className="input-group-text text-left"
                isRequired={true}
                fontSize="sm"
                mb="24px"
                size="lg"
                type="text"
                variant="auth"
                value={cpf}
                onKeyUp={handleCpfChange}
                onChange={(e) => setCpf(e.target.value)}
              />

                <FormLabel
                    ms="4px"
                    mt="12px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Data Nasc:<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <CustomInput
                      id="birthdate"
                      isRequired={true}
                      fontSize="sm"
                      mb="24px"
                      size="lg"
                      type="text"
                      variant="auth"
                      value={birthdate}
                      onChange={handleBirthdateChange}
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px"></InputRightElement>
                  </InputGroup>
                  {ageError && (
                    <Text color="red.500" fontSize="sm">
                      {ageError}
                    </Text>
                  )}

              <FormLabel
                ms="1px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Nome da Mãe<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                id="nomeMae"
                className="input-group-text text-left"
                isRequired={true}
                fontSize="sm"
                placeholder="Nome da Mãe"
                mb="14px"
                size="lg"
                type={"text"}
                variant="auth"
                maxLength={30}
                value={momName}
                onChange={(e) => setMomName(e.target.value)}
              />

              <FormLabel
                display="flex"
                ms="1px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                id="email"
                className="input-group-text text-left"
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="mail@simmmple.com"
                mb="14px"
                fontWeight="500"
                size="lg"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <FormLabel
                ms="4px"
                mt="12px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                (DDD) + Whatsapp/Celular<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                id="cellphone"
                isRequired={true}
                fontSize="sm"
                mb="24px"
                size="lg"
                type="text"
                variant="auth"
                value={cellphone}
                onChange={(e) => setCellphone(e.target.value)}
              />

              <FormLabel
                ms="1px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Senha<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  id="senhaa"
                  className="input-group-text text-left"
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Min. 8 digitos"
                  mb="14px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>

              <FormLabel
                ms="1px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Confirmar senha<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  id="senhab"
                  className="input-group-text text-left"
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Confirmar senha"
                  mb="24px"
                  size="lg"
                  type={show2 ? "text" : "password"}
                  variant="auth"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show2 ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick2}
                  />
                </InputRightElement>
              </InputGroup>

              <Stack direction="row" alignItems="center" mb="16px">
                <FormLabel
                  ms="1px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                  mb="0"
                >
                  Pessoa Politicamente Exposta (PEP)?
                </FormLabel>
                <Checkbox
                  isChecked={pep.sim}
                  onChange={() => handlePepChange("sim")}
                  borderRadius="md"
                  borderWidth="0px"
                >
                  Sim
                </Checkbox>
                <Checkbox
                  isChecked={pep.nao}
                  onChange={() => handlePepChange("nao")}
                  borderRadius="md"
                  borderWidth="0px"
                >
                  Não
                </Checkbox>
              </Stack>

              <InputGroup size="md" style={{ display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
                <FormLabel
                  id="termo"
                  ms="1px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                  style={{ fontWeight: "bold" }}
                >
                  <input type="checkbox" name="terms" id="terms" onClick={() => setTerms(!terms)} checked={terms} /> &nbsp;
                  Aceitar os &nbsp;
                  <NavLink to="/termos" color="blue.700" target="_blank">
                    <Text color="blue.500">
                      Termos
                    </Text>
                  </NavLink>
                  &nbsp;e a&nbsp;
                  <NavLink to="/politica" color="blue.700" target="_blank">
                    <Text color="blue.500">
                      Politica de Privacidade
                    </Text>
                  </NavLink>
                </FormLabel>
              </InputGroup>

              <Button
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                isDisabled={isButtonDisabled || !cpfValido || cpf.length < 14}
              >
                {loading ? "Carregando..." : "Cadastrar"}
              </Button>
              <NavLink to="/auth/sign-in">
                <CustomButton isFullWidth={true} variant={"tertiary"}>Voltar</CustomButton>
              </NavLink>
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default SignUp;
